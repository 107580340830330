import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const PhotographyPage = () => {
  return (
    <Layout>
      <h1 className="hidden lg:block lg:desktop-header">Dana's Photography</h1>
      <h1 className="mobile-header lg:hidden">Photography</h1>
      <div className="flex flex-wrap gap-4 justify-evenly pt-4">
        <StaticImage
          width={420}
          src="../images/photography/01.jpg"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          width={420}
          src="../images/photography/02.jpg"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          width={420}
          src="../images/photography/03.jpg"
          alt="Bob's art"
          placeholder="tracedSVG"
          className="flex-shrink-0"
          layout="fixed"
        />
        <StaticImage
          width={630}
          src="../images/photography/art.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          layout="fixed"
        />
        <StaticImage
          width={630}
          src="../images/photography/art2.png"
          alt="Bob's art"
          placeholder="tracedSVG"
          layout="fixed"
        />
      </div>
    </Layout>
  )
}

export default PhotographyPage
